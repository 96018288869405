import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/index.vue'),
		redirect: '/mattercollect/list',
    children:[
      // {
      //   path:'',
      //   name:'HelpHome',
      //   component: () => import('../views/helpInfo/helpHome.vue')
      // },
      {
        path:'mattercollect',
        name:'MatterCollect',
        component: () => import('../views/helpInfo/matterCollect.vue'),
        children:[
          {
            path:'list',
            name:'List',
            component: () => import('../views/matterList/list.vue'),
          },
          {
            path:'detail',
            name:'Detail',
            component: () => import('../views/matterList/detail.vue'),
          },
          {
            path:'search',
            name:'Search',
            component: () => import('../views/search/search.vue'),
          },
        ]
      },
      {
        path:'newhandarea',
        name:'newHandArea',
        component: () => import('../views/helpInfo/newHandArea.vue')
      },
    ]
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
})
export default router
