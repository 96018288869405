import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './util/element'
import './assets/css/index.scss'
// import 'element-ui/lib/theme-chalk/base.css';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
Vue.config.productionTip = false
Vue.component(CollapseTransition.name, CollapseTransition)

const getQueryParams = ()=>{
  if(location.href.indexOf('?')){
      const index = location.href.indexOf('?')
      return location.href.slice(index+1).split('&').reduce((pre,cur)=>{
          pre[cur.split('=')[0]] = cur.split('=')[1]
          return pre
      },{})
  }
  return {}
}

const query = getQueryParams()
// 是否显示首尾链接
console.log(query)
if(query.hideHeadTail){
  sessionStorage.setItem('hideHeadTail',query.hideHeadTail)
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')